<template>
  <v-dialog
    v-model="services.showCreateFather"
    width="500"
  >
    <v-card>
      <v-card-title v-if="!isEditing">
        Create Father Node
      </v-card-title>
      <v-card-title v-else>
        Edit Father Node
      </v-card-title>

      <v-card-text>
        <v-form
          ref="fatherForm"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="fatherForm.node_name"
            label="Father Node Name"
            required
            :rules="name_rules"
            :counter="255"
          />
          <v-text-field
            v-model="fatherForm.node_description"
            label="Father Node Description"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!isEditing"
          light
          color="color_green"
          style="color:white"
          class="ml-4"
          rounded
          small
          :disabled="checkForm()"
          @click="createFather()"
        >
          Create Father Node
        </v-btn>
        <v-btn
          v-else
          light
          color="color_green"
          style="color:white"
          class="ml-4"
          rounded
          small
          :disabled="checkForm()"
          @click="editFather()"
        >
          Edit Father Node
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, manageLenghtError } from '@/formRules.js';
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    fatherForm: {
      type: Object,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isTranslating: {
      type: Boolean,
      default: false
    },
    selectedLanguage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      name_rules: [...required, ...manageLenghtError(255)],
      valid: true
    };
  },
  computed: {
    ...mapState(['services', 'ticketing'])
  },
  methods: {
    checkForm() {
      return !(this.fatherForm.node_name !== '' && this.valid);
    },
    createFather() {
      const id = uuidv4();
      const service_id = JSON.parse(JSON.stringify(this.services.service_id));

      const objToPush = {
        service_id: service_id,
        node_name: this.fatherForm.node_name,
        node_description: this.fatherForm.node_description,
        node_id: id,
        father_id: '',
        children: [],
        sort_id: ''
      };

      if (!this.ticketing.isProfiling) {
        objToPush.sort_id = ++this.services.storeFatherSort_id;
      } else {
        objToPush.sort_id = 1;
      }

      this.services.hierarchyItems.push(objToPush);
      this.services.showCreateFather = false;
    },
    editFather() {
      this.services.hierarchyItems.forEach(father => {
        if (father.node_id == this.fatherForm.node_id) {
          father.node_name = this.fatherForm.node_name;
          father.node_description = this.fatherForm.node_description;
        }
      });
      this.services.showCreateFather = false;
    }
  }
};
</script>
